import { React, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { Container, Row } from 'react-bootstrap';
import {
  Routes, Route, Navigate, useLocation,
} from 'react-router-dom';

import AdminUsers from './components/AdminUsers';
import AdminActivities from './components/AdminActivities';
import AllUsersStats from './components/AllUsersStats';
import NotFound from './components/NotFound';
import MenubarTop from './components/MenubarTop';
import Profile from './components/Profile';
import StravaOAuthCallback from './components/StravaOAuthCallback';
import { listYears } from './services/years';

const App = () => {
  const [currentYear, setCurrentYear] = useState(null);
  const [pastYears, setPastYears] = useState([]);

  const urlSelected = useLocation().pathname.split('/')[1];

  useEffect(() => {
    const getYears = async () => {
      const years = await listYears();
      setCurrentYear(years.data.find((y) => y.current));
      setPastYears(years.data.filter((y) => !y.current));
    };
    if (!currentYear) getYears();
  });

  const statsRoute = (path, year) => (
    <Route
      path={path}
      element={
        (
          <AllUsersStats
            year={year ? year.year : 0}
            showCategories={year ? year.hasCategories : false}
            showAwards={year ? new Date().setHours(0, 0, 0, 0) > new Date(year.endDate) : false}
          />
        )
      }
    />
  );

  const getSelected = () => {
    if (!urlSelected) {
      return currentYear;
    }
    for (const y of pastYears) {
      if (parseInt(urlSelected, 10) === y.year) {
        return y;
      }
    }
    return null;
  };

  return (
    <div className="App">
      <Container fluid className="p-0">
        <Row className="m-0">
          <MenubarTop
            selectedYear={getSelected()}
            pastYears={pastYears.map((y) => y.year).sort().reverse()}
          />
        </Row>
      </Container>
      <Container fluid className="noPadding" style={{ paddingLeft: 0 }}>
        <Routes>
          {statsRoute('/', currentYear)}
          <Route path="/home" element={<Navigate replace to="/" />} />
          {currentYear && <Route path={`/${currentYear.year}`} element={<Navigate replace to="/" />} />}
          {pastYears.map((y) => (
            statsRoute(`/${y.year}`, y)
          ))}
          <Route path="/admin/users" element={<AdminUsers />} />
          <Route
            path="/admin/activities"
            element={<AdminActivities year={currentYear ? currentYear.year : 0} />}
          />
          <Route path="/stravaOAuthCallback" element={<StravaOAuthCallback />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Container>
    </div>
  );
};

export default App;
