import axios from 'axios';
import { API } from './api';

const getAllUsersStats = (year, category) => axios.get(`${API}/years/${year}/users/stats?category=${category}`);

const getOverallStats = (year) => axios.get(`${API}/years/${year}/users/stats-overall`);

export {
  getAllUsersStats,
  getOverallStats,
};
