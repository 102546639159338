import { React, useEffect, useState } from 'react';
import {
  ButtonGroup, Container, Image, Spinner, ToggleButton,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPersonBiking, faRoad, faMountain, faBolt,
} from '@fortawesome/free-solid-svg-icons';
import { getAllUsersStats } from '../services/stats';
import AllUsersStatsTable from './AllUsersStatsTable';
import AllUsersStatsTableMobile from './AllUsersStatsTableMobile';
import { useViewport } from '../helpers/custom-hooks';

const AllUsersStats = ({ year, showCategories, showAwards }) => {
  const [fetchedData, setFetchedData] = useState([]);
  const [maleData, setMaleData] = useState([]);
  const [femaleData, setFemaleData] = useState([]);
  const [category, setCategory] = useState('');
  const { width } = useViewport();
  const breakpoint = 1060;

  const categories = [
    {
      name: 'Všetko', value: '', variant: 'outline-secondary', icon: faPersonBiking,
    },
    {
      name: 'Cestné', value: 'road', variant: 'outline-primary', icon: faRoad,
    },
    {
      name: 'Horské', value: 'mountain', variant: 'outline-danger', icon: faMountain,
    },
    {
      name: 'Elektro', value: 'ebike', variant: 'outline-success', icon: faBolt,
    },
  ];

  useEffect(() => {
    if (year) fetchStats();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, category]);

  const fetchStats = () => {
    clearData();
    getAllUsersStats(year, category).then(
      (response) => {
        response.data.forEach((athlete) => {
          if (athlete.sex === 'M') setMaleData((oldData) => [...oldData, athlete]);
          else setFemaleData((oldData) => [...oldData, athlete]);
        });
        setFetchedData(response);
      },
      (error) => {
        setFetchedData(
          (error.response
              && error.response.data
              && error.response.data.message)
            || error.message
            || error.toString(),
        );
      },
    );
  };

  const clearData = () => {
    setFetchedData([]);
    setMaleData([]);
    setFemaleData([]);
  };

  const categorySelector = () => (
    <ButtonGroup className="ms-4" style={{ width: '410px' }}>
      {categories.map((cat, idx) => (
        <ToggleButton
          key={`category-${cat.name}`}
          id={`category-${idx}`}
          type="radio"
          name="category"
          className="w-25 py-1"
          style={{ boxShadow: 'none' }}
          variant={cat.variant}
          value={cat.value}
          checked={category === cat.value}
          onChange={(e) => setCategory(e.currentTarget.value)}
        >
          <FontAwesomeIcon icon={cat.icon} className="me-2" />
          {cat.name}
        </ToggleButton>
      ))}
    </ButtonGroup>
  );

  return (
    <div>
      {(year === 0 || !fetchedData.data) && process.env.NODE_ENV !== 'test' ? (
        <Container className="mt-3">
          <Spinner animation="border" />
        </Container>
      ) : (
        <div>
          {(width < breakpoint) ? (
            <Container className="mt-3">
              <div style={{ marginBottom: '50px' }}>
                {showCategories
                && (
                <>
                  <h4 style={{ marginBottom: '10px' }}>Kategória</h4>
                  {categorySelector()}
                </>
                )}
                <h2 style={{ marginTop: '25px' }}>Ženy</h2>
                <AllUsersStatsTableMobile data={femaleData} showAwards={showAwards} />
                <h2 style={{ marginTop: '50px' }}>Muži</h2>
                <AllUsersStatsTableMobile data={maleData} showAwards={showAwards} />
                <Image
                  src="/api_logo_pwrdBy_strava_horiz_light.png"
                  style={{ width: '200px', marginTop: '50px' }}
                />
              </div>
            </Container>
          ) : (
            <div>
              <Container fluid className="p-0 vh-100 d-flex flex-column">
                {showCategories
                && (
                <div style={{ marginLeft: '4%', marginTop: '20px', textAlign: 'left' }}>
                  <h4 style={{ display: 'inline', verticalAlign: 'middle' }}>Kategória</h4>
                  {categorySelector()}
                </div>
                )}
                <div style={{ marginLeft: '4%', marginRight: '4%' }}>
                  <h2 style={{ marginTop: '20px' }}>Ženy</h2>
                  <AllUsersStatsTable data={femaleData} showAwards={showAwards} />
                  <h2 style={{ marginTop: '50px' }}>Muži</h2>
                  <AllUsersStatsTable data={maleData} showAwards={showAwards} />
                </div>
                <div style={{ marginLeft: '4%', marginRight: '4%' }}>
                  <Image
                    src="/api_logo_pwrdBy_strava_horiz_light.png"
                    style={{
                      width: '200px', marginTop: '20px', paddingBottom: '20px', float: 'right',
                    }}
                  />
                </div>
              </Container>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

AllUsersStats.propTypes = {
  year: PropTypes.number.isRequired,
  showCategories: PropTypes.bool,
  showAwards: PropTypes.bool,
};

AllUsersStats.defaultProps = {
  showCategories: false,
  showAwards: false,
};

export default AllUsersStats;
